<template>
  <aside id="sidebar-second">
    <div class="block block-views" data-bid="269">
      <h3 class="title">お知らせ</h3>
      <div class="view view-info view-id-info view-display-id-block">
        <template v-for="content in info" :key="content">
          <div class="view-content">
            <div class="views-row">
              <span class="views-field views-field-timestamp"> <span class="field-content">●</span>
              </span> <div class="views-field views-field-field-category">
                <div class="field-content"><span class="category">{{ content.Category }}</span></div>
              </div> <span class="views-field views-field-title">
                <span class="field-content"><router-link :to="'/mansion/info/' + content.Id">{{content.Title}}</router-link></span>
              </span>
            </div>
          </div>
        </template>
        <div class="more-link">
          <router-link to="/mansion/info"> もっと見る </router-link>
        </div>
      </div>
    </div>
    <!-- /block -->
  </aside>
  <!-- /sidebar-second -->
</template>
<script>
import config from "../config.js";
import { getData } from "../assets/js/request.js";
export default {
  name: "Sidebar_second_login",
  data(){
    return {
      info : []
    }
  },
  async created(){
    var info = await getData(config.url.get.info_list);
    // [{ Id, Title, Category, ReleaseDate, BodySummary}]
    if(info.length > 5){
      info.splice(5, info.length - 5);
    }
    
    Object.assign(this.$data, {
      info: info
    });
  }
};
</script>

<style scoped src="../assets/css/ennevision_column_categories.css" />
<style scoped src="../assets/css/ennevision_columns.css" />
