<template>
  <div id="page" class="page not-logged-in">
    <div id="sp-menus">
      <div class="block block-ennevision-mypage" data-bid="414">
        <form action="/mansion/inquiry" method="post" id="search-block-form" accept-charset="UTF-8">
          <div>
            <div class="container-inline">
              <h2 class="element-invisible">検索フォーム</h2>
              <div class="form-item form-type-textfield form-item-search-block-form">
                <label class="element-invisible" for="edit-search-block-form--2">検索 </label>
                <input title="検索したいキーワードを入力してください" type="text" id="edit-search-block-form--2" name="search_block_form" value="" size="15" maxlength="128" class="form-text" />
              </div>
              <div class="form-actions form-wrapper" id="edit-actions"><input type="submit" id="edit-submit" name="op" value="検索" class="form-submit" /></div>
              <input type="hidden" name="form_build_id" value="form-jTYLlH73Vq-eohP9PhyciDUAP4gNmcwjig0rEaah7Yk" />
              <input type="hidden" name="form_id" value="search_block_form" />
            </div>
          </div>
        </form>
      </div>
      <!-- /block -->
      <div class="block block-menu sp-highlight-menus" data-bid="418">
        <ul class="menu">
          <li class="first leaf"><router-link to="/mansion/faq_top">よくあるご質問</router-link></li>
          <li class="leaf active-trail"><router-link to="/mansion/inquiry" class="active-trail active">お問い合わせ</router-link></li>
          <li class="last leaf"><router-link to="/mansion/entry">電気の利用申込み</router-link></li>
        </ul>
      </div>
      <!-- /block -->
      <div class="block block-menu sp-sub-menus" data-bid="374">
        <ul class="menu"></ul>
      </div>
      <!-- /block -->
      <div class="block block-menu sp-etc-menus" data-bid="377">
        <ul class="menu">
          <li class="first leaf"><router-link to="/mansion/inquiry_document" id="id_sp_etc_inq">お問い合わせ</router-link></li>
        </ul>
      </div>
      <!-- /block -->
    </div>
    <header id="header">
      <div class="container">
        <router-link to="/mansion/" title="" rel="home" id="logo"><img src="../assets/images/logo.png" alt="" /></router-link>
        <div id="header-region">
          <div class="block block-search" data-bid="265">
            <form action="#" method="post" id="search-block-form--2" accept-charset="UTF-8" @submit.prevent="push()">
              <div>
                <div class="container-inline">
                  <h2 class="element-invisible">検索フォーム</h2>
                  <div class="form-item form-type-textfield form-item-search-block-form">
                    <label class="element-invisible" for="edit-search-block-form--4">検索 </label>
                    <input title="検索したいキーワードを入力してください" type="text" id="edit-search-block-form--4" name="search_block_form" size="15" maxlength="128" class="form-text" v-model="search" />
                  </div>
                  <div class="form-actions form-wrapper" id="edit-actions--2"><input type="submit" id="edit-submit--2" name="op" value="検索" class="form-submit" /></div>
                </div>
              </div>
            </form>
          </div>
          <!-- /block -->
          <div class="block block-block" data-bid="580">
            <div class="anonymous_header">
              <div class="login">
                <router-link class="login_link" to="/mansion/">ログインページに<br />もどる</router-link>
              </div>
              <div class="entry"><router-link to="/mansion/entry">電気の利用申込み</router-link></div>
            </div>
            <!-- /block -->
          </div>
        </div>
      </div>
    </header>
    <!-- /header -->
  </div>
</template>

<script>
import $ from "jquery";
import MEMS from "../assets/js/mems.js";
import { init } from "../assets/js/responsive_menus_simple.js";
import { isLogin, removeData, loginStore } from "../stores/store.js";
export default {
  name: "Header",
  data() {
    if(!isLogin()){
      //$('link[rel="stylesheet"][href="/mansion/blue/style.css"].css').removeAttr('disabled');
      $('link[rel="stylesheet"][href="/mansion/red/style.css"].css').attr('disabled', 'disabled');
      $('link[rel="stylesheet"][href="/mansion/green/style.css"].css').attr('disabled', 'disabled');
    }
    MEMS.settings = {
      scroll_to_top: { label: "" },
      responsive_menus: [
        {
          toggler_text: "&#x2261;",
          selectors: ["#sp-menus"],
          media_size: "1024",
          media_unit: "px",
          absolute: true,
          remove_attributes: true,
          responsive_menus_style: "responsive_menus_simple",
        },
      ],
      password: {
        addLowerCase: "小文字を追加してください",
        addNumbers: "数字を追加",
        addPunctuation: "記号を追加",
        addUpperCase: "大文字を追加",
        confirmFailure: "いいえ",
        confirmSuccess: "はい",
        confirmTitle: "パスワードの一致：",
        fair: "適正",
        good: "良い",
        hasWeaknesses: "あなたのパスワードをより強固にするために",
        sameAsUsername: "ユーザー名と同じにしないでください",
        strengthTitle: "パスワードの強度：",
        strong: "強い",
        tooShort: "少なくとも8文字以上にしてください",
        weak: "弱い",
        username: "　",
      },
    };
    return { search: "", isLogin: isLogin() };
  },
  mounted: function () {
    this.$nextTick(function () {
      if (document.querySelector(".title")) {
        document.title = document.querySelector(".title").innerText + " | でんき案内板";
      }
      init();
      MEMS.behaviors.scroll_to_top.attach(document, MEMS.settings);
      MEMS.behaviors.responsive_menus.attach(document, MEMS.settings);
      $("body").trigger("responsive-menus-load");
      $(document).trigger("ready");
      $(document).off("ready");
    });
  },
  beforeRouteLeave(to, from, next) {
    var path = this.$router.currentRoute._rawValue.path;
    if(path == "/mansion/create/password"){
      removeData("token");
      removeData("x-token");
      removeData("last_login_datetime");
      loginStore().$reset();
      sessionStorage.clear();
    }
    next();
  },
  methods: {
    push: function () {
      var keyword = this.$data.search.trim();
      this.$data.search = "";
      this.$router.push({
        path: "/mansion/search",
        query: {
          keyword: encodeURIComponent(keyword),
        },
      });
    },
  },
};
import "../assets/js/mems_faq_searchkey_half_space.js";
import "../assets/js/responsive_menus_simple.js";
import "../assets/js/scroll_to_top.js";
</script>

<style scoped src="../assets/css/style.css" />
